import React from "react"
import Navbar from "../navbar"

import { Link } from "gatsby"
import background from "../../assets/download/download-background.jpg"
import coverImage from "../../assets/download/download-cover-image.jpg"

import FinalFooter from "./footer"

const DownloadScreen = () => {
    return (
        <>
            <div id="download">
                <Navbar static={false} />
                <div className="download-bottom" style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                }}>
                    <h3>Download freebies</h3>
                    <p>
                        Please tell others about <em>SOUND</em>, its stories, and the creators who brought them
                    to life. We hope they inspire you to create tales of your own.
                </p>
                    <div className="row">
                        <div className="col-sm-6 order-sm-2" style={{ padding: '3rem 3rem 3rem 1rem' }}>
                            <img src={coverImage} style={{
                                marginBottom: '0',
                                height: '40vh',
                                width: '100%',
                            }}/>
                            <div style={{
                                width: '100%',
                                height: '10vh',
                                backgroundColor: 'rgb(0,50,185)',
                                fontSize: '.75rem',
                                fontWeight: 'bold',
                                color: 'white',
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '5vh',
                                }}>
                                    <div style={{float: 'left', marginLeft: '5px'}}>
                                        Mobile Wallpapers
                                    </div>
                                    <div style={{float: 'right', marginRight: '5px'}}>
                                        <a href="/downloads/SOUND_A_Comics_Anthology_mobile_wallpaper.zip" style={{color: 'white', textDecoration:'underline'}}>
                                            Download
                                            </a>
                                    </div>
                                </div>
                                <div style={{
                                    width: '100%',
                                    height: '5vh',
                                }}>
                                    <div style={{float: 'left', marginLeft: '5px'}}>
                                        Zoom Wallpapers
                                    </div>
                                    <div style={{float: 'right', marginRight: '5px'}}>
                                        <a href="/downloads/SOUND_A_Comics_Anthology_zoom_wallpaper.zip" style={{color: 'white', textDecoration:'underline'}}>
                                            Download
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 order-sm-1" style={{ padding: '3rem' }}>
                            <h1 className="quote" style={{ fontSize: '3rem', marginBottom: '0', color: 'rgb(255, 65, 185)' }}>
                                "These tales should not end once you reach the last page of the book."
                        </h1>
                            <p style={{
                                marginTop: '0',
                                marginBottom: '0',
                            }}>
                                |
                        </p>
                            <p style={{ fontSize: '16px' }}>
                                Budjette Tan, Co-editor of <br />
                            <em>SOUND: A Comics Anthology</em>
                        </p>
                        </div>
                    </div>

                    <div className="scroll-footer" style={{ bottom: '43vh', fontSize: '0.6rem' }}>
                        <Link to="/#screen-6" className="back">&larr;Back to home</Link>
                    </div>
                </div>

                <FinalFooter />
            </div>
        </>
    );
}

export default DownloadScreen

/*<div className="col-sm order-sm-2" style={{
                        }}>
                            <img src={poster} style={{
                                width: '100%',
                                height: '88%',
                                marginBottom: '0px',
                            }} />
                            <div style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                float: 'left',
                                marginLeft: '3px',
                            }}>
                                Digital Postcard
                            </div>
                            <div style={{
                                fontSize: '11px',
                                float: 'right',
                                marginRight: '0.5rem',
                            }}>
                                <em>
                                    &gt;Download
                                </em>
                            </div>
                        </div>
                        <div className="col-sm order-sm-3" style={{
                        }}>
                            <img src={poster} style={{
                                width: '100%',
                                height: '88%',
                                marginBottom: '0px',
                            }} />
                            <div style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                float: 'left',
                                marginLeft: '3px',
                            }}>
                                Zoom backgrounds
                            </div>
                            <div style={{
                                fontSize: '11px',
                                float: 'right',
                                marginRight: '0.5rem',
                            }}>
                                <em>
                                    &gt;Download
                                </em>
                            </div>
                        </div>*/

/*
<div class="download-poster">

</div>
<div class="download-bookmarks">

</div>
<div class="download-background">

</div>*/


/*<table>
                    <tbody>
                        <tr>
                            <td>
                                <div className="download-poster">
                                    <figure style={{width:'100%', height:'100%', margin:'0'}}>
                                        <img src={poster} width="100%" height="90%" style={{margin:'0'}}/>
                                        <figcaption style={{color:'black', textAlign:'center',}}>
                                            Poster
                                        </figcaption>
                                    </figure>
                                </div>
                            </td>
                            <td>
                                <div className="download-bookmarks">
                                    <figure style={{width:'100%', height:'100%', margin:'0'}}>
                                        <img src={poster} width="100%" height="90%" style={{margin:'0'}}/>
                                        <figcaption style={{color:'black', textAlign:'center'}}>
                                            Bookmarks
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="download-background">
                                    <figure style={{width:'100%', height:'100%', margin:'0'}}>
                                        <img src={background} width="100%" height="90%" style={{margin:'0'}}/>
                                        <figcaption style={{color:'black', textAlign:'center'}}>
                                            Background
                                        </figcaption>
                                    </figure>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>*/