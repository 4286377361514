import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar"

import DownloadScreen from "../components/screens/download"

const DownloadPage = () => (
  <Layout>
    <SEO title="Download" />
    <DownloadScreen/>
  </Layout>
)

export default DownloadPage
