import React from "react"
import { Link } from "gatsby"
import { useState, useEffect, useRef } from 'react'

import logo from "../assets/logo-horizontal.png";
import logoVert from "../assets/logo.png";

//import SvgHamburgerWavyBlue from "./hamburger";

import pngHamburger from "../assets/hamburger_wavy.png"

let $;
if (typeof window !== `undefined`) {
  $ = require("jquery");
}

const Listlink = props => (
    // insert styles or element here
    <Link to={props.to} onClick={props.clicked}>{props.children}</Link>
)

const Navbar = (props) => {
    const [staticNav, setStaticNav] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [navbarClass, setNavbarClass] = useState(staticNav ? "navbar navbar-light amber lighten-4 mb-4 fixed-top hide" : "navbar navbar-light amber lighten-4 mb-4 fixed-top");

    const lastScrollTop = useRef(null);
    const offsetNav = useRef(null);
    
    let navProp = showMenu ? '100%' : '0%';
    function openNav() {
        /* ensures that the body can't be scrolled while the menu is open*/
        document.documentElement.className = showMenu ? '' : 'no-scroll';
        /* a bit of a gimmicky trick because the screen-2 navbar causes a scroll due to the
         * curtain reveal transition animation. so this code adds a z-index of -1 to screen-1
         * so that it is hidden behind screen-2 and it also takes away whatever translateY on
         * screen-2 that has resulted from the transition animation
         */
        if(document.getElementById('screen-2') != null) {
            document.getElementById('screen-2').className = showMenu ? '' : 'negate-screen-2-transform';
            document.getElementById('screen-1').className = showMenu ? '' : 'negate-screen-1-z-index';
        }
        setShowMenu(!showMenu);
        return false;
    }
    
    const handleScroll=(e) => {
        const window = e.currentTarget;

        let webpageHeight = document.body.scrollHeight;
           
        if(window.scrollY > (webpageHeight - window.innerHeight * 1.5)) {
            document.getElementById('scroll-down').style.display = 'none';
        } else {
            document.getElementById('scroll-down').style.display = 'unset';
        }
        /* if screen-2 doesn't exist, then the navbar is not on the main page
         * which means that we don't ahve to worry about the additional navbar
         * on screen-2
         */
        
        if(document.getElementById('screen-2') == null) {
            setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top');
            /*
            if (lastScrollTop.current > window.scrollY) {
                //console.log("scrolling up");
                setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top');
            } else if (lastScrollTop.current < window.scrollY) {
                //console.log("scrolling down");
                setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top hide');
            }
            */
            lastScrollTop.current = window.scrollY;
            return;
        }
        

        if(window.scrollY > (document.getElementById('screen-2').offsetTop) ) {
            offsetNav.current = 0;
            //console.log("you've scrolled past screen 2");
            setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top');
            /*
            if (lastScrollTop.current > window.scrollY) {
                //console.log("scrolling up");
                setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top');
            } else if (lastScrollTop.current < window.scrollY) {
                //console.log("scrolling down");
                setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top hide');
            }*/
            lastScrollTop.current = window.scrollY;
        } else if(window.scrollY < document.getElementById('screen-2').offsetTop) {
            offsetNav.current = document.getElementById('screen-1').offsetHeight;
            setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top hide');
        }
        

    }

    useEffect(() => {
        //root = document.documentElement;
        if(props.static) {
            setStaticNav(true);
        }
        else setStaticNav(false);
    }, [props.static]);

    function throttle(ms, callback) {
        var timer, lastCall=0;
    
        return function() {
            var now = new Date().getTime(),
                diff = now - lastCall;
            console.log(diff, now, lastCall);
            if (diff >= ms) {
                console.log("Call callback!");
                lastCall = now;
                callback.apply(this, arguments);
            }
        };
    }

    useEffect(() => {
        if(props.static) {
            if(document.getElementsByClassName('overlay').length > 1)
                document.getElementsByClassName('overlay')[1].style.transform = 'translateY(-' + (document.getElementById('screen-2').offsetTop - window.scrollY) + 'px)';
            return;
        };
        lastScrollTop.current = window.scrollY;
        window.addEventListener('scroll', e => throttle(30, handleScroll(e)));
        return () => window.removeEventListener('scroll', e => handleScroll(e));
    });

    useEffect(() => {
        if(props.static || document.getElementById('screen-1') === null) return;
        offsetNav.current = document.getElementById('screen-1').offsetHeight;
        setNavbarClass('navbar navbar-light amber lighten-4 mb-4 fixed-top hide');
        console.log(offsetNav.current);
    }, []);

    function handleClick() {
        document.documentElement.className = '';
        document.getElementById('screen-2').className = '';
        document.getElementById('screen-1').className = '';

        setShowMenu(false);
    }


    return (
        <div id="navbar">
            <nav className={props.screentwo ? 'navbar navbar-light amber lighten-4 mb-4' : navbarClass}>
            <div id="myNav" className="overlay"
                style={{
                    width: navProp,
                    //transform: transformOverlay,
                    maxWidth: 'none !important',
                }}
                >
                <a href="javascript:" className="closebtn" onClick={openNav}>&times;</a>
  
                <div className="overlay-content">
                <Listlink to="/#screen-1" clicked={handleClick}>Home</Listlink>
                <Listlink to="/#canvas" clicked={handleClick}>Create</Listlink>  
                <Listlink to="/#stories" clicked={handleClick}>Stories</Listlink>
                <Listlink to="/#about-sound" clicked={handleClick}>About <em>SOUND</em></Listlink> 
                <a target="_blank" href="https://differenceengine.sg/comics/sound-a-comics-anthology/" onClick={handleClick}>Buy the comic</a>
                <Listlink to="/download" clicked={handleClick}>Download freebies</Listlink>  
                <Listlink to="/#about-de" clicked={handleClick}>About Difference Engine</Listlink>
                    <img src={logoVert} width="100" height="80" alt="logo"/>
                </div>
            </div>   
                <img src={pngHamburger} width="30" height="30" onClick={openNav} className="navbar-nav" id="hamburger"/>
                
                <a className="logo" href="#">
                    <img src={logo} width="200" height="60" alt="logo"/>
                </a>
                <a className="navbar-brand" target="_blank" href="https://differenceengine.sg/comics/sound-a-comics-anthology/">
                <button type="button" className="navbar-button">Buy the comic</button>
                </a>
            { !props.screentwo  && 
            <div className="scroll-footer" id="scroll-down">
                Scroll down
            </div>
            }
            </nav>
        </div>
    );
};

export default Navbar;